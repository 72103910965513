import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Social from "../components/social";
import * as aboutStyles from "./about.module.css"
import Img from "gatsby-image"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About" />
      {posts.map(post => {
        var featuredImgFluid;
        if (post.frontmatter.image !== null) {
          featuredImgFluid = post.frontmatter.image.childImageSharp.fluid
        }

        return (
          <>
            <div className = {aboutStyles.row}>
              <div className = {aboutStyles.left}>
                <h1>
                 {post.frontmatter.title}
                </h1>
                {!!featuredImgFluid?<Img fluid={featuredImgFluid} className = {aboutStyles.profilepic}/>:''}

              </div>

              <div className = {aboutStyles.right}
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
                />
            </div>
          </>
          )
        })}
        <div className = {aboutStyles.row}>
          <div className = {aboutStyles.left}>
          </div>
          <div className = {aboutStyles.right}>
            <Social />
          </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___index], order: ASC }
      filter: {fileAbsolutePath: {regex: "/(about)/"}}
    ) {
      nodes {
        html
        frontmatter {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
